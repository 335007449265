<template>
    <div>
        <h1 class="text-lg mb-1 font-rubik">{{ $t('articleConfig.accountMapping') }}</h1>
        <div class="table-container">

            <table class="data-table mt-2">
                <thead>
                    <tr>
                        <th class="text-base font-semibold">{{ $t('articleConfig.articleType') }}</th>
                        <th class="text-base font-semibold">{{ $t('articleConfig.VATRate') }}</th>
                        <th class="text-base font-semibold">{{ $t('articleConfig.costCenter') }}</th>
                        <th class="text-base font-semibold" v-if="hidden_fields.mapping_article_account_coding === true">{{ $t('articleConfig.accountCoding') }}</th>
                        <th class="text-base font-semibold" v-if="hidden_fields.mapping_se_sale_account === true">{{ $t('articleConfig.SESalesAccount') }}</th>
                        <th class="text-base font-semibold" v-if="hidden_fields.mapping_eu_sale_account === true">{{ $t('articleConfig.EUSalesAccount') }}</th>
                        <th class="text-base font-semibold" v-if="hidden_fields.mapping_export_sale_account === true">{{ $t('articleConfig.exportSalesAccount') }}</th>
                        <!-- <th>Action</th> -->
                    </tr>
                </thead>
                <tbody>
                    <!-- Loop through your data to generate rows -->
                    <tr v-for="(item, index) in tableData" :key="item.id">
                        <td class="text-base font-semibold">{{ getArticleTypeName(item, 'typeName') }}</td>
                        <td>{{ item.vat_rate }}</td>
                        <td>{{ getArticleTypeName(item, "cost_center") }}</td>
                        <td v-if="hidden_fields.mapping_article_account_coding === true">{{ getArticleTypeName(item, "article_account_coding") }}</td>
                        <td v-if="hidden_fields.mapping_se_sale_account === true">{{ getArticleTypeName(item, "se_sale_account") }}</td>
                        <td v-if="hidden_fields.mapping_eu_sale_account === true">{{ getArticleTypeName(item, "eu_sale_account") }}</td>
                        <td v-if="hidden_fields.mapping_export_sale_account === true">{{ getArticleTypeName(item, "export_sale_account") }}</td>
                        <button @click="deleteRow(item.uuid)">{{ $t('articleConfig.delete') }}</button>

                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mt-10">
            <div class="customWitdh">
                <label class="text-base font-semibold">{{ $t('articleConfig.articleType') }}</label>
                <v-select class="w-full" v-model="formData.article_type" :options="typeOptions" label="name"
                    :filterable="true" @search="getTypeOptions"></v-select>
            </div>
            <div class="customWitdh">
                <label class="text-base font-semibold">{{ $t('articleConfig.VATRate') }}</label>
                <v-select class="w-full" v-model="formData.vat_rate" :options="vatRateOptions" label="name"
                    :filterable="true"></v-select>
            </div>
            <div class="customWitdh">
                <label class="text-base font-semibold">{{ $t('articleConfig.costCenter') }}</label>
                <v-select class="w-full" v-model="formData.cost_center" :options="costCenterOptions" label="description"
                    :filterable="true" @search="getCostCenterOptions"></v-select>
            </div>
            <div class="customWitdh" v-if="hidden_fields.mapping_article_account_coding === true">
                <label class="text-base font-semibold">{{ $t('articleConfig.accountCoding') }}</label>
                <v-select class="w-full" v-model="formData.account_coding" :options="accountCodingOptions" label="name"
                    :filterable="true" @search="getAccountCodingOptions"></v-select>
            </div>
            <div class="customWitdh" v-if="hidden_fields.mapping_eu_sale_account === true">
                <label class="text-base font-semibold">{{ $t('articleConfig.SESalesAccount') }}</label>
                <v-select class="w-full" v-model="formData.eu_sale_account" :options="SESalesOptions" label="description"
                    :filterable="true" @search="getSESalesOptions"></v-select>
            </div>
            <div class="customWitdh" v-if="hidden_fields.mapping_se_sale_account === true">
                <label class="text-base font-semibold">{{ $t('articleConfig.EUSalesAccount') }}</label>
                <v-select class="w-full" v-model="formData.se_sale_account" :options="SESalesOptions" label="description"
                    :filterable="true" @search="getSESalesOptions"></v-select>
            </div>
            <div class="customWitdh" v-if="hidden_fields.mapping_export_sale_account === true">
                <label class="text-base font-semibold">{{ $t('articleConfig.exportSalesAccount') }}</label>
                <v-select class="w-full" v-model="formData.export_sale_account" :options="SESalesOptions"
                    label="description" :filterable="true" @search="getSESalesOptions"></v-select>
            </div>

            <div class="btn-main">
                <button class="addBtn" @click="addButtons">
                    <span>
                        {{ $t('articleConfig.add') }}
                    </span>
                </button>

            </div>
        </div>

    </div>
</template>
  
<script>
import customSelect from '@/@core/customComponent/customSelect.vue';
import vSelect from 'vue-select';
import PAGES from "@/services/helpers/constant";

export default {
    components: { customSelect, vSelect },
    data() {
        return {
            hidden_fields: {...JSON.parse(JSON.stringify(PAGES['configuration']['article_configuration']))},
            tableData: [],
            typeOptions: [],
            vatRateOptions: [{ name: '25%', value: '25' }, { name: '12%', value: '12' }, { name: '6%', value: '6' }, { name: '0%', value: '0' }],
            costCenterOptions: [],
            accountCodingOptions: [],
            SESalesOptions: [],
            formData: {
                export_sale_account: null,
                eu_sale_account: null,
                se_sale_account: null,
                article_type: null,
                vat_rate: null,
                cost_center: null,
                account_coding: null
            }
        }
    },
    created() {
        this.disabledFields()
    },
    mounted() {
        this.getData()
        this.getTypeOptions()
        this.getCostCenterOptions()
        this.getAccountCodingOptions()
        this.getSESalesOptions()
    },
    methods: {
        disabledFields() {
            let hidden_object = this.hiddenResource
            try {
                // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
                hidden_object['hidden_fields']['configuration']['article_configuration'].map(item => {
                this.hidden_fields[item] = false
                })
            } catch (e) {

            }
        },
        getArticleTypeName(item, key) {
            if (key == "cost_center") {

                return item.cost_center ? item.cost_center.description : '';
            } else if (key == "typeName") {
                return item.article_type ? item.article_type.name : '';

            } else if (key === "article_account_coding") {
                return item.article_account_coding && item.article_account_coding.name ? 
                    item.article_account_coding.name : 
                    item.article_account_coding.name_english || '';
            }

            else if (key == "se_sale_account") {
                return item.se_sale_account ? item.se_sale_account.number : '';

            }
            else if (key == "eu_sale_account") {
                return item.eu_sale_account ? item.eu_sale_account.number : '';

            }
            else if (key == "export_sale_account") {
                return item.export_sale_account ? item.export_sale_account.number : '';

            }
        },

        deleteRow(uuid) {
            this.$swal({
                title: this.$t('articleConfig.confirmation'),
                text: this.$t('articleConfig.deletionInformation'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: this.$t('articleConfig.confirm'),
            }).then(async (result) => {
                if (result.isConfirmed) {

                    try {
                        const response = await this.$useJwt.customConfiguration({ URL: '/golf_custom_accounts/' + uuid, method: 'delete' })
                        this.popupMsg(
                            this.$t('Message.Success'),
                            this.$t('articleConfig.customdeleteAccount'),
                            'CheckIcon',
                            'success'
                        );

                        this.getData()
                        // this.$swal('Deleted!', 'Your row has been deleted.', 'success');
                    } catch (error) {

                    }
                }
            });
        },

        async getData() {
            console.log(this.hidden_fields)
            try {
                const response = await this.$useJwt.customConfiguration({ URL: '/golf_custom_accounts', method: 'get' })
                this.tableData = response.data?.results

            } catch (error) {

            }


        },
        async getTypeOptions(search = "") {
            try {
                const response = await this.$useJwt.getCall(`/git_article_types?search=${search}`)
                this.typeOptions = response.data?.results

            } catch (error) {

            }
        },

        async getCostCenterOptions(search = "") {
            try {
                const response = await this.$useJwt.getAllRegisters(`costcenters?search=${search}`)
                this.costCenterOptions = response.data?.results

            } catch (error) {

            }
        },
        async getAccountCodingOptions(search = "") {
            try {
                const response = await this.$useJwt.getAllRegisters(`account_codings?search=${search}`)
                this.accountCodingOptions = response.data?.results

            } catch (error) {

            }
        },
        async getSESalesOptions(search = "") {
            try {
                const response = await this.$useJwt.getAllRegisters(`accounts?search=${search}`)
                this.SESalesOptions = response.data?.results?.map((item) => ({ ...item, description: item.number + " - " + item.description }))

            } catch (error) {

            }
        },

        async addButtons() {
            // Add your logic to handle button addition
            const payload = {
                export_sale_account: this.formData.export_sale_account?.id,
                eu_sale_account: this.formData.eu_sale_account?.id,
                se_sale_account: this.formData.se_sale_account?.id,
                article_type: this.formData.article_type?.id,
                vat_rate: this.formData.vat_rate?.value,
                cost_center: this.formData.cost_center?.id,
                article_account_coding: this.formData.account_coding?.id
            }
            try {
                const response = await this.$useJwt.customConfiguration({ URL: '/golf_custom_accounts', method: 'post' }, payload);
                this.popupMsg(
                    this.$t('Message.Success'),
                    response.data.message,
                    'CheckIcon',
                    'success'
                );
                this.getData()
                this.formData = {
                    export_sale_account: '',
                    eu_sale_account: '',
                    se_sale_account: '',
                    article_type: '',
                    vat_rate: '',
                    cost_center: '',
                    account_coding: ''

                }


            } catch (error) {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    error.response?.data?.message,
                    'AlertTriangleIcon',
                    'danger'
                );
            }
        },


    }
};

</script>
  
<style lang="scss">
.row {
    display: flex;
    align-items: center;
    margin-left: .2rem;
}

.title {
    color: var(--others-body-font, #27364B);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
}

.label {
    color: #232D39;
    // font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.btn-main {
    display: flex;
    align-items: center;
    // min-width: 12% !important;
    justify-content: center;
    margin-top: 20px;
    margin-left: 80px;
}

.addBtn {
    border-radius: 0.375rem;
    background: #4FCFC0;
    padding: 10px 20px 10px 20px;
    color: #FFF;
    font-style: normal;
    line-height: normal;
    border: none;
    width: 80px;
}

.table-container {
    overflow-x: auto;
    /* Enable horizontal scroll */
    max-width: 100%;
    /* Adjust as needed */
}


.data-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        color: #232D39;
        // font-family: Roboto;
        font-size: 12px;
        padding: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border-bottom: 1px solid #ddd;
        /* Add this line to add a bottom border to each cell */

    }

    th {
        font-size: inherit; // Reset the font-size to inherit from the class
        font-weight: inherit; // Reset the font-weight to inherit from the class
        padding: 8px;
        text-align: left;
        color: #232D39;
        // font-family: Roboto;
        // font-size: 12px;
        font-style: normal;
        // font-weight: 700;
        line-height: normal;
        border-bottom: 1px solid #ddd;
        /* Add this line to add a bottom border to each cell */

        // background-color: #f2f2f2;
    }

    button {
        color: white;
        padding: 10px 20px 10px 20px;
        border: none;
        cursor: pointer;
        border-radius: 0.375rem;
        margin: 10px;
        background: #FB4B4B;
        color: #FFF;
        font-style: normal;
        line-height: normal;
        margin-left: 10px;
    }




}

// .label {
//     color: #232D39;
//     // font-family: Roboto;
//     font-size: 12px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: normal;
// }

.customWitdh {
    min-width: 12% !important;
    margin-right: .5rem !important;

}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
  